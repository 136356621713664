import { useEffect, useState } from "react";
import ModalSendFinancialOffer from "./ModalSendFinancialOffer";
import { Link } from "react-router-dom";
import ModalStopOffer from "Components/ModalStopOffer/ModalStopOffer";
import axiosInstance from "../../axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalRate from "Components/ModalRate/ModalRate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastContainerApp from "Components/ToastContainerApp/ToastContainerApp";
const CardProductDetails = ({
  img,
  altImg,
  badgeText,
  titleCard,
  titleAddress,
  iconDollar,
  priceCard,
  typePrice,
  textPrice,
  iconCalendar,
  dateCard,
  textInfoDate,
  dateCard2,
  textInfoDate2,
  dateCard3,
  textInfoDate3,
  isActiveDate,
  numWatch,
  textWatch,
  numOffer,
  textOffer,
  numDay,
  isActiveMainButtons,
  isActiveButtonUpdateAndStop,
  isActiveFinalCard,
  isActiveOffersWatch,
  routeCard,
  projectId,
  noNavigation,
  formWorkProject,
  noRoute,
  offers,
  refetch,
  hideEndBtn,
  showRateBtn,
  hideRateBtn,
  is_favorite,
  status,
}) => {
  // CHANGE COLOR ACTIVE BUTTON
  const { t } = useTranslation();
  const [fillBg, setFillColor] = useState("#B0B0B0"); // Initial fill color
  const [borderColorIcon, setBorderColorIcon] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const changeIconBg = (e) => {
    axiosInstance
      .post("/favourite", { type_id: projectId, type: "project" })
      .then((res) => {
        const newColor = fillBg === "#B0B0B0" ? "#fff" : "#B0B0B0";
        // toast.success(
        //   is_favorite
        //     ?t("toast.removedFromFavourites")
        //     : t("toast.addedToFavourites")
        // );
        setFillColor(newColor); // CHANGE FILL COLOR SVG
        setBorderColorIcon(!borderColorIcon); // CHANGE BORDER COLOR ICON
      });
  };

  // OEPN ANDCLOSE MODAL
  const [showModal, setShowModal] = useState(false);
  const [hideofferBtn, setHideofferBtn] = useState(false);
  // CLOSE MODAL
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // OPEN MODAL
  const handleShowModal = () => {
    setShowModal(true);
  };

  // OEPN ANDCLOSE MODAL STOP OFFER
  const [showModalStopOffer, setShowModalStopOffer] = useState(false);
  const [showModalRate, setShowModalRate] = useState(false);

  // CLOSE MODAL
  const handleCloseStopOffer = () => {
    axiosInstance
      .post("/projectend", { project_id: projectId })
      .then((res) => {
        setShowModalStopOffer(false);
        toast.success("تم انهاء المشروع بنجاح");
        if (refetch) {
          refetch();
        }
      })
      .catch((err) => {});
  };
  // OPEN MODAL
  const handleShowModalStop = (e) => {
    e.preventDefault();
    setShowModalStopOffer(true);
  };
  useEffect(() => {
    console.log(offers);
    if (offers) {
      offers?.map((offer) => {
        if (offer?.provider?.id == userId) {
          console.log(userId, offer.provider.id);
          setHideofferBtn(true);
        }
      });
    }
  }, [offers]);
  useEffect(() => {
    if (status == 4) {
      setHideofferBtn(true);
    }
  }, [status]);

  const notify = () => toast(t("codysharinglinkdone"));

  return (
    <>
      <ModalSendFinancialOffer
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        projectId={projectId}
        startDatee={dateCard3}
        endDate={dateCard}
        refetch={refetch}
      />
      <ModalStopOffer
        showModalStopOffer={showModalStopOffer}
        onHide={setShowModalStopOffer}
        handleCloseStopOffer={handleCloseStopOffer}
        titleHeaderModal={t("titleHeaderModal")}
        textModalStop={t("textModalStop")}
        titleButtonCancel={t("titleButtonCancel")}
        titleButtonBack={t("titleButtonBack")}
        setShowModalStopOffer={setShowModalStopOffer}
      />
      <ModalRate
        refetch={refetch}
        projectId={projectId}
        showModalStopOffer={showModalRate}
        onHide={setShowModalRate}
        titleHeaderModal={t("RateHeader")}
        textModalStop={t("textModalStop")}
        titleButtonCancel={t("titleButtonCancel")}
        titleButtonBack={t("titleButtonBack")}
      />

      {/* ======== START RECENT PROJECT ONE ========== */}
      <div className="recent-project-one card-one-tab ">
        <Link
          to={
            noNavigation
              ? null
              : `${
                  formWorkProject ? "/myProjects/" : ""
                }${routeCard}/${projectId}`
          }
          state={formWorkProject ? { showOwner: true } : null}
        >
          {/* =========== START HEAD CARD =========== */}
          <div className="head-card d-flex justify-content-between align-items-center flex-wrap gap-3">
            <div className="info-right d-flex align-items-center flex-wrap  gap-3">
              <div className="img">
                <img
                  style={{
                    // maxWidth: "151px",
                    // maxHeight: "80px",
                    maxWidth: "160px",
                    maxHeight: "160px",
                    borderRadius: "8px",
                  }}
                  src={img}
                  alt={altImg}
                />
              </div>
              <div className="info-text">
                <div className="badge-text">
                  {status === 0
                    ? t("Waiting for management approval")
                    : status === 1
                    ? t("Opend")
                    : status === 2
                    ? t("refused")
                    : status === 3
                    ? t("in progress")
                    : status === 4
                    ? t("completed")
                    : status === 5
                    ? t("Closed")
                    : ""}
                </div>
                <h2 className="title title-top">{titleCard}</h2>
                <div className="icon-star d-flex  align-items-start  gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12.0009 14.1699C9.87086 14.1699 8.13086 12.4399 8.13086 10.2999C8.13086 8.15994 9.87086 6.43994 12.0009 6.43994C14.1309 6.43994 15.8709 8.16994 15.8709 10.3099C15.8709 12.4499 14.1309 14.1699 12.0009 14.1699ZM12.0009 7.93994C10.7009 7.93994 9.63086 8.99994 9.63086 10.3099C9.63086 11.6199 10.6909 12.6799 12.0009 12.6799C13.3109 12.6799 14.3709 11.6199 14.3709 10.3099C14.3709 8.99994 13.3009 7.93994 12.0009 7.93994Z"
                      fill="#002C63"
                    />
                    <path
                      d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z"
                      fill="#002C63"
                    />
                  </svg>
                  <span className="title">{titleAddress}</span>
                </div>
              </div>
            </div>

            {isActiveMainButtons && !hideofferBtn && (
              <div className="main-buttons d-flex  align-items-center  gap-3">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/project/${projectId}`
                    );
                    notify();
                  }}
                  className="sharing-btn btn-main mt-0"
                >
                  {t("sharingbtn")}
                  <FontAwesomeIcon icon={faShare} />
                </button>
                <ToastContainerApp />
                <button onClick={handleShowModal} className="btn-main mt-0">
                  {t("sendOffer")}
                </button>

                <div
                  className={`icon-svg-tab ${borderColorIcon ? "active" : ""}`}
                  onClick={changeIconBg}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M15 11.9004H10C9.59 11.9004 9.25 11.5604 9.25 11.1504C9.25 10.7404 9.59 10.4004 10 10.4004H15C15.41 10.4004 15.75 10.7404 15.75 11.1504C15.75 11.5604 15.41 11.9004 15 11.9004Z"
                      fill={fillBg}
                    />
                    <path
                      d="M12.5 14.46C12.09 14.46 11.75 14.12 11.75 13.71V8.70996C11.75 8.29996 12.09 7.95996 12.5 7.95996C12.91 7.95996 13.25 8.29996 13.25 8.70996V13.71C13.25 14.12 12.91 14.46 12.5 14.46Z"
                      fill={fillBg}
                    />
                    <path
                      d="M19.5703 23.25C19.0603 23.25 18.5003 23.1 17.9603 22.79L13.0803 20.08C12.7903 19.92 12.2203 19.92 11.9303 20.08L7.05031 22.79C6.06031 23.34 5.05031 23.4 4.28031 22.94C3.51031 22.49 3.07031 21.58 3.07031 20.45V6.36C3.07031 3.82 5.14031 1.75 7.68031 1.75H17.3303C19.8703 1.75 21.9403 3.82 21.9403 6.36V20.45C21.9403 21.58 21.5003 22.49 20.7303 22.94C20.3803 23.15 19.9803 23.25 19.5703 23.25ZM12.5003 18.46C12.9703 18.46 13.4303 18.56 13.8003 18.77L18.6803 21.48C19.1903 21.77 19.6603 21.83 19.9603 21.65C20.2603 21.47 20.4303 21.04 20.4303 20.45V6.36C20.4303 4.65 19.0303 3.25 17.3203 3.25H7.68031C5.97031 3.25 4.57031 4.65 4.57031 6.36V20.45C4.57031 21.04 4.74031 21.48 5.04031 21.65C5.34031 21.82 5.81031 21.77 6.32031 21.48L11.2003 18.77C11.5703 18.56 12.0303 18.46 12.5003 18.46Z"
                      fill={fillBg}
                    />
                  </svg>
                </div>
              </div>
            )}

            {isActiveButtonUpdateAndStop && !showRateBtn && (
              <div className="buttons-update-stop d-flex  align-items-center  gap-3">
                {/* <button className="btn-main mt-0">تعديل</button> */}
                <button
                  onClick={handleShowModalStop}
                  className="btn-main button-home  mt-0"
                >
                  {t("endProject")}
                </button>
              </div>
            )}
            {showRateBtn && hideRateBtn && (
              <div className="buttons-update-stop d-flex  align-items-center  gap-3">
                {/* <button className="btn-main mt-0">تعديل</button> */}
                <button
                  onClick={() => setShowModalRate(true)}
                  className="btn-main mt-0"
                >
                  {t("Rate")}
                </button>
              </div>
            )}
          </div>
          {isActiveOffersWatch && (
            <div className="final-card d-flex align-items-center mt-3 justify-content-between flex-wrap gap-3 ">
              <div className="all-details-card d-flex align-items-center flex-wrap   gap-2">
                <div className="details-card-1 d-flex align-items-center  gap-2">
                  <div className="icon-svg-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0009 13.6083C8.00924 13.6083 6.39258 11.9916 6.39258 9.99993C6.39258 8.00827 8.00924 6.3916 10.0009 6.3916C11.9926 6.3916 13.6092 8.00827 13.6092 9.99993C13.6092 11.9916 11.9926 13.6083 10.0009 13.6083ZM10.0009 7.6416C8.70091 7.6416 7.64258 8.69993 7.64258 9.99993C7.64258 11.2999 8.70091 12.3583 10.0009 12.3583C11.3009 12.3583 12.3592 11.2999 12.3592 9.99993C12.3592 8.69993 11.3009 7.6416 10.0009 7.6416Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M10.0004 17.5167C6.86706 17.5167 3.90872 15.6834 1.87539 12.5001C0.992057 11.1251 0.992057 8.8834 1.87539 7.50007C3.91706 4.31673 6.87539 2.4834 10.0004 2.4834C13.1254 2.4834 16.0837 4.31673 18.1171 7.50007C19.0004 8.87507 19.0004 11.1167 18.1171 12.5001C16.0837 15.6834 13.1254 17.5167 10.0004 17.5167ZM10.0004 3.7334C7.30872 3.7334 4.73372 5.35007 2.93372 8.17507C2.30872 9.15007 2.30872 10.8501 2.93372 11.8251C4.73372 14.6501 7.30872 16.2667 10.0004 16.2667C12.6921 16.2667 15.2671 14.6501 17.0671 11.8251C17.6921 10.8501 17.6921 9.15007 17.0671 8.17507C15.2671 5.35007 12.6921 3.7334 10.0004 3.7334Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                  </div>
                  <h2 className="text">
                    <span className="num-text">{numWatch}</span>
                    {textWatch}
                  </h2>
                </div>
                <div className="details-card-1 d-flex align-items-center  gap-2">
                  <div className="icon-svg-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.4993 18.9584H7.49935C2.97435 18.9584 1.04102 17.0251 1.04102 12.5001V7.50008C1.04102 2.97508 2.97435 1.04175 7.49935 1.04175H12.4993C17.0243 1.04175 18.9577 2.97508 18.9577 7.50008V12.5001C18.9577 17.0251 17.0243 18.9584 12.4993 18.9584ZM7.49935 2.29175C3.65768 2.29175 2.29102 3.65841 2.29102 7.50008V12.5001C2.29102 16.3417 3.65768 17.7084 7.49935 17.7084H12.4993C16.341 17.7084 17.7077 16.3417 17.7077 12.5001V7.50008C17.7077 3.65841 16.341 2.29175 12.4993 2.29175H7.49935Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M13.125 8.125H6.875C6.53333 8.125 6.25 7.84167 6.25 7.5C6.25 7.15833 6.53333 6.875 6.875 6.875H13.125C13.4667 6.875 13.75 7.15833 13.75 7.5C13.75 7.84167 13.4667 8.125 13.125 8.125Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M13.125 13.125H6.875C6.53333 13.125 6.25 12.8417 6.25 12.5C6.25 12.1583 6.53333 11.875 6.875 11.875H13.125C13.4667 11.875 13.75 12.1583 13.75 12.5C13.75 12.8417 13.4667 13.125 13.125 13.125Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                  </div>
                  <h2 className="text">
                    <span className="num-text">{numOffer}</span>
                    {textOffer}
                  </h2>
                </div>
              </div>
              <div>{numDay}</div>
            </div>
          )}

          <div className="center-info d-flex align-items-center justify-content-between flex-wrap   gap-4">
            <div className="info-content-one d-flex align-items-center gap-3">
              <img src={iconDollar} alt="icon dollar" />
              <div className="text-info">
                <h2 className="num-info">
                  {priceCard}
                  {typePrice}
                </h2>
                <p className="text-cont">{textPrice}</p>
              </div>
            </div>
          </div>
          {/*  <div className="info-content-one d-flex align-items-center gap-3">
              <img src={iconCalendar} alt="icon Calendar" />
              <div className="text-info">
                <h2 className="title-date">{dateCard}</h2>
                <p className="text-cont">{textInfoDate}</p>
              </div>
            </div>
            {isActiveDate && (
              <div className="info-content-one d-flex align-items-center gap-3">
                <img src={iconCalendar} alt="icon Calendar" />
                <div className="text-info">
                  <h2 className="title-date">{dateCard2}</h2>
                  <p className="text-cont">{textInfoDate2}</p>
                </div>
              </div>
            )}
            {isActiveDate && (
              <div className="info-content-one d-flex align-items-center gap-3">
                <img src={iconCalendar} alt="icon Calendar" />
                <div className="text-info">
                  <h2 className="title-date">{dateCard3}</h2>
                  <p className="text-cont">{textInfoDate3}</p>
                </div>
              </div>
            )}
          </div> */}
          {/* =========== START CENTER INFO =========== */}

          {isActiveFinalCard && (
            <div className="final-card d-flex align-items-center  gap-2 justify-content-between flex-wrap gap-3 ">
              <div className="all-details-card d-flex align-items-center flex-wrap   gap-2">
                <div className="details-card-1 d-flex align-items-center  gap-2">
                  <div className="icon-svg-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0009 13.6083C8.00924 13.6083 6.39258 11.9916 6.39258 9.99993C6.39258 8.00827 8.00924 6.3916 10.0009 6.3916C11.9926 6.3916 13.6092 8.00827 13.6092 9.99993C13.6092 11.9916 11.9926 13.6083 10.0009 13.6083ZM10.0009 7.6416C8.70091 7.6416 7.64258 8.69993 7.64258 9.99993C7.64258 11.2999 8.70091 12.3583 10.0009 12.3583C11.3009 12.3583 12.3592 11.2999 12.3592 9.99993C12.3592 8.69993 11.3009 7.6416 10.0009 7.6416Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M10.0004 17.5167C6.86706 17.5167 3.90872 15.6834 1.87539 12.5001C0.992057 11.1251 0.992057 8.8834 1.87539 7.50007C3.91706 4.31673 6.87539 2.4834 10.0004 2.4834C13.1254 2.4834 16.0837 4.31673 18.1171 7.50007C19.0004 8.87507 19.0004 11.1167 18.1171 12.5001C16.0837 15.6834 13.1254 17.5167 10.0004 17.5167ZM10.0004 3.7334C7.30872 3.7334 4.73372 5.35007 2.93372 8.17507C2.30872 9.15007 2.30872 10.8501 2.93372 11.8251C4.73372 14.6501 7.30872 16.2667 10.0004 16.2667C12.6921 16.2667 15.2671 14.6501 17.0671 11.8251C17.6921 10.8501 17.6921 9.15007 17.0671 8.17507C15.2671 5.35007 12.6921 3.7334 10.0004 3.7334Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                  </div>
                  <h2 className="text">
                    <span className="num-text">{numWatch}</span>
                    {textWatch}
                  </h2>
                </div>
                <div className="details-card-1 d-flex align-items-center  gap-2">
                  <div className="icon-svg-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12.4993 18.9584H7.49935C2.97435 18.9584 1.04102 17.0251 1.04102 12.5001V7.50008C1.04102 2.97508 2.97435 1.04175 7.49935 1.04175H12.4993C17.0243 1.04175 18.9577 2.97508 18.9577 7.50008V12.5001C18.9577 17.0251 17.0243 18.9584 12.4993 18.9584ZM7.49935 2.29175C3.65768 2.29175 2.29102 3.65841 2.29102 7.50008V12.5001C2.29102 16.3417 3.65768 17.7084 7.49935 17.7084H12.4993C16.341 17.7084 17.7077 16.3417 17.7077 12.5001V7.50008C17.7077 3.65841 16.341 2.29175 12.4993 2.29175H7.49935Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M13.125 8.125H6.875C6.53333 8.125 6.25 7.84167 6.25 7.5C6.25 7.15833 6.53333 6.875 6.875 6.875H13.125C13.4667 6.875 13.75 7.15833 13.75 7.5C13.75 7.84167 13.4667 8.125 13.125 8.125Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M13.125 13.125H6.875C6.53333 13.125 6.25 12.8417 6.25 12.5C6.25 12.1583 6.53333 11.875 6.875 11.875H13.125C13.4667 11.875 13.75 12.1583 13.75 12.5C13.75 12.8417 13.4667 13.125 13.125 13.125Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                  </div>
                  <h2 className="text">
                    <span className="num-text">{numOffer}</span>
                    {textOffer}
                  </h2>
                </div>
              </div>
              {/* <div className="times-details">
                <div className="icon-clock">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M9.5 17.0625C5.0525 17.0625 1.4375 13.4475 1.4375 9C1.4375 4.5525 5.0525 0.9375 9.5 0.9375C13.9475 0.9375 17.5625 4.5525 17.5625 9C17.5625 13.4475 13.9475 17.0625 9.5 17.0625ZM9.5 2.0625C5.675 2.0625 2.5625 5.175 2.5625 9C2.5625 12.825 5.675 15.9375 9.5 15.9375C13.325 15.9375 16.4375 12.825 16.4375 9C16.4375 5.175 13.325 2.0625 9.5 2.0625Z"
                      fill="#377DFF"
                    />
                    <path
                      d="M12.283 11.9476C12.1855 11.9476 12.088 11.9251 11.998 11.8651L9.67297 10.4776C9.09547 10.1326 8.66797 9.37507 8.66797 8.70757V5.63257C8.66797 5.32507 8.92297 5.07007 9.23047 5.07007C9.53797 5.07007 9.79297 5.32507 9.79297 5.63257V8.70757C9.79297 8.97757 10.018 9.37507 10.2505 9.51007L12.5755 10.8976C12.8455 11.0551 12.928 11.4001 12.7705 11.6701C12.658 11.8501 12.4705 11.9476 12.283 11.9476Z"
                      fill="#377DFF"
                    />
                  </svg>
                </div>
                <span className="text">{numDay}</span>
              </div> */}
            </div>
          )}
        </Link>
      </div>
      {/* ======== END RECENT PROJECT ONE ========== */}
    </>
  );
};

export default CardProductDetails;
