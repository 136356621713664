import * as Yup from "yup";
import InputFiled from "../InputForm/InputField";
import FormField from "../FormComponent/FormFiled";
import LogoImageForm from "../LogoForm/LogoImageForm";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ImageComponent from "../ImageLogin/ImageComponent";
import ReactFlagsSelect from "react-flags-select";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BasicAccountInformation.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useTranslation } from "react-i18next";
import CustomReactSelect from "../ReactSelect/ReactSelect";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import TermsConditionsModal from "Pages/TermsConditions/TermsConditionsModal";

const BasicAccountInformation = () => {
  const location = useLocation();
  const [userType, setUserType] = useState();
  const [cites, setCites] = useState([]);
  const [category, setcategory] = useState([]);
  const [showTerms, setShowTerms] = useState(false);

  const [countryError, setCountryError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCate, setSelectedCate] = useState("");

  useEffect(() => {
    console.log(selectedCate);
  }, [selectedCate]);

  const [CateError, setCateError] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    axiosInstance
      .get("/city", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const data = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });
        setCites(data);
      });
    axiosInstance
      .get("/category", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const data = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });
        setcategory(data);
      });
  }, [i18n.language]);
  console.log(category);

  const Router = useNavigate();
  const isEmiratiPhoneNumber = (val) => {
    // Regular expression to match Emirati phone numbers without +971
    const uaeRegex = /^(0?5\d{8})$/;

    return uaeRegex.test(val);
  };
  const validationSchemaforNotCustomer = Yup.object().shape({
    name: Yup.string().required(t("EnterName")),
    company_name_ar: Yup.string().required(t("EnterCompanyName")),
    company_name_en: Yup.string().required(t("EnterCompanyName")),
    email: Yup.string().email(t("InvalidEmail")).required(t("EmailRequired")),
    phone: Yup.string()
      .matches(/^(0?5\d{8})$/, "Invalid UAE phone number")
      .test(
        "is-emirati-number",
        "Phone number must be Emirati",
        isEmiratiPhoneNumber
      )
      .required("Phone is required"),
    password: Yup.string()
      .required(t("PasswordRequired"))
      .min(8, t("PasswordTooShort")),
  });

  const initialValues = {
    name: "",
    company_name_ar: "",
    company_name_en: "",
    email: "",
    phone: "",
    password: "",
    region: "",
  };

  useEffect(() => {
    setUserType(
      location.state.specialty == 0
        ? "contractor"
        : location.state.specialty == 1
        ? "advisor"
        : "provider"
    );
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    if (userType == "provider") {
      if (!selected || !selectedCate) {
        setCountryError(!selected);
        setCateError(!selectedCate);
        return;
      }
    } else {
      if (!selected) {
        console.log("selected", selected);
        setCountryError(!selected);

        return;
      }
    }

    setLoading(true);
    let phoneNumber;

    if (values.phone.startsWith("0")) {
      phoneNumber = values.phone.slice(1);
    } else {
      phoneNumber = values.phone;
    }
    let postData;
    if (userType == "provider") {
      postData = {
        ...values,
        city_id: selected.value,
        type: userType,
        categories: selectedCate?.map((item) => item.value),
        phone: phoneNumber,
      };
    } else {
      postData = {
        ...values,
        city_id: selected.value,
        type: userType,
        phone: phoneNumber,
      };
    }
    axiosInstance
      .post("/register", postData, {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        toast.success(t("RegistrationSuccess"));
        setLoading(false);
        setLoading(false);
        Router("/loginForm");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.errors.email) {
          toast.error(t("EmailExists"));
        } else if (err.response.data.errors.phone) {
          toast.error(t("PhoneExists"));
        } else {
          toast.error(t("SomethingWentWrong"));
        }
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <TermsConditionsModal show={showTerms} setShow={setShowTerms} />

      <HelmetInfo titlePage={t("BasicAccountInformationTitle")} />
      <div className="login-form basicAccountInformation">
        <div className="logo-top">
          <LogoImageForm />
        </div>
        <div className="container-fluid">
          <div className="all-Login-form">
            <div className="row g-3 align-items-center">
              <div className="col-12 col-md-6">
                <div className="info-form-login position-relative">
                  <IconHeaderForm
                    routerPage={"/LoginForm"}
                    iconHeader={faArrowRight}
                  />
                  <div className="all-form-log">
                    <HeaderForm
                      titleHeaderForm={t("AccountInfoTitle")}
                      textHeaderForm={t("AccountInfoText")}
                    />
                    <FormField
                      initialValues={initialValues}
                      validationSchema={validationSchemaforNotCustomer}
                      onSubmit={handleSubmit}
                    >
                      <div className="row g-3">
                        <div className="col-12 col-md-12">
                          <InputFiled
                            label={t("Name")}
                            name="name"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("CompanyNameEN")}
                            name="company_name_en"
                            type="text"
                            placeholder=""
                            success={""}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("CompanyNameAR")}
                            name="company_name_ar"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("Email")}
                            name="email"
                            type="email"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("PhoneNumber")}
                            name="phone"
                            type="tel"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <CustomReactSelect
                            placeholder={t("SelectCity")}
                            options={cites}
                            changeValue={setSelected}
                            className=" mt-3"
                          />
                          {countryError && (
                            <div className="error">{t("CountrySelect")}</div>
                          )}
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("Region")}
                            name="region"
                            type="text"
                            placeholder=""
                            success={undefined}
                          />
                        </div>
                        {location.state.specialty == 2 && (
                          <div className="col-12 col-md-12">
                            <CustomReactSelect
                              placeholder={t("Select Category")}
                              options={category}
                              changeValue={setSelectedCate}
                              isMulti={true}
                              className=" mt-3"
                            />
                            {CateError && (
                              <div className="error">
                                {t("please select Category")}
                              </div>
                            )}
                          </div>
                        )}
                        <div className="col-12 col-md-12">
                          <div className="input-pass position-relative">
                            <InputFiled
                              label={t("Password")}
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder=""
                              success
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <span
                              className="icon-eye-button"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-check check-input-field d-flex align-items-center gap-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {t("TermsAndConditions")}{" "}
                          <span
                            onClick={() => setShowTerms(true)}
                            className="link-check"
                          >
                            {t("TermsLink")}
                          </span>
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-main btn-submit w-100 mt-5"
                      >
                        {loading ? (
                          <span className="loader"></span>
                        ) : (
                          t("Continue")
                        )}
                      </button>
                    </FormField>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="info-login position-relative">
                  <ImageComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicAccountInformation;
