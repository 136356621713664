import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReactFlagsSelect from "react-flags-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import "./ModalAccount.css";
import ModalConfirmNumber from "./ModalConfirmNumber";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import CustomReactSelect from "Components/Forms/ReactSelect/ReactSelect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ModalEditInfoAccount = ({
  showModalEdit,
  handleCloseModalEdit,
  data,
  refetch,
}) => {
  const isEmiratiPhoneNumber = (val) => {
    // Regular expression to match Emirati phone numbers without +971
    const uaeRegex = /^(0?5[056]\d{7})$/;

    return uaeRegex.test(val);
  };
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const type = useSelector((state) => state.auth.type);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("NameRequired")),
    company_name_ar: Yup.string().required(t("CompanyNameArRequired")),
    company_name_en: Yup.string().required(t("CompanyNameEnRequired")),
    email: Yup.string().email(t("InvalidEmail")).required(t("EmailRequired")),

    phone: Yup.string()
      .matches(/^(0?5\d{8})$/, "Invalid UAE phone number")

      .test(
        "is-emirati-number",
        "Phone number must be Emirati",
        isEmiratiPhoneNumber
      )
      .required(t("PhoneNumberRequired")),
  });
  const validationSchemaforCustomer = Yup.object().shape({
    name: Yup.string().required(t("NameRequired")),
    email: Yup.string().email(t("InvalidEmail")).required(t("EmailRequired")),

    phone: Yup.string()
      .matches(/^(0?5\d{8})$/, "Invalid UAE phone number")

      .test(
        "is-emirati-number",
        "Phone number must be Emirati",
        isEmiratiPhoneNumber
      )
      .required(t("PhoneNumberRequired")),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    console.log(values);
    let phoneNumber;

    if (values?.phone?.startsWith("0")) {
      phoneNumber = values.phone.slice(1);
    } else {
      phoneNumber = values.phone;
    }
    axiosInstance
      .post("/profile", {
        ...values,
        city_id: selectedCity.value,
        phone: phoneNumber,
      })
      .then((res) => {
        toast.success(t("ProfileUpdated"));
        refetch();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      axiosInstance
        .get("/city", {
          headers: {
            "Accept-Language": i18n.language,
          },
        })
        .then((res) => {
          const dataa = res.data.data.map((item) => {
            return { value: item.id, label: item.title };
          });
          const selected = dataa.find((item) => item.value === data?.city_id);

          setSelectedCity(selected);
          setCity(dataa);
        });
    }
  }, [data]);

  const initialValues = {
    name: data?.name,

    company_name_ar: data?.company_name_ar,
    company_name_en: data?.company_name_en,
    email: data?.email,
    phone: data?.phone,
    region: data?.region,
  };
  const initialValuesForCustomer = {
    name: data?.name,
    email: data?.email,
    phone: data?.phone,
    region: data?.region,
  };
  const [selected, setSelected] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [showModalConfirmNumber, setShowModalConfirmNumber] = useState(false);

  const handleCloseModalConfirmNumber = () => {
    setShowModalConfirmNumber(false);
  };

  const handleShowModalConfirmNumber = () => {
    setShowModalConfirmNumber(true);
  };

  return (
    <>
      <ModalConfirmNumber
        showModalConfirmNumber={showModalConfirmNumber}
        handleCloseModalConfirmNumber={handleCloseModalConfirmNumber}
      />
      <CustomModal
        show={showModalEdit}
        onHide={handleCloseModalEdit}
        title={t("EditAccountInfo")}
        newClass={"modal-account"}
      >
        <div className="row g-3 align-items-center">
          <div className="col-12 col-md-12">
            <div className="info-form-login position-relative">
              <div className="all-form-log">
                <p className="text">{t("EnterAccountInfo")}</p>
                <FormField
                  initialValues={
                    type != "customer"
                      ? initialValues
                      : initialValuesForCustomer
                  }
                  validationSchema={
                    type != "customer"
                      ? validationSchema
                      : validationSchemaforCustomer
                  }
                  onSubmit={handleSubmit}
                >
                  <div className="row g-3">
                    {type != "customer" ? (
                      <>
                        <div className="col-12 col-md-12">
                          <InputFiled
                            label={t("name")}
                            name="name"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("CompanyNameEn")}
                            name="company_name_ar"
                            type="text"
                            placeholder=""
                            success={""}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("CompanyNameAr")}
                            name="company_name_en"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>
                      </>
                    ) : (
                      <div className="col-12 col-md-12">
                        <InputFiled
                          label={t("name")}
                          name="name"
                          type="text"
                          placeholder=""
                          success
                        />
                      </div>
                    )}
                    <div className="col-12 col-md-6">
                      <InputFiled
                        label={t("Email")}
                        name="email"
                        type="email"
                        placeholder=""
                        success
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <InputFiled
                        label={t("PhoneNumber")}
                        name="phone"
                        type="number"
                        placeholder=""
                        success
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <CustomReactSelect
                        placeholder={t("SelectCityPlaceholder")}
                        options={city}
                        changeValue={setSelectedCity}
                        defaultValue={selectedCity}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <InputFiled
                        label={t("Region")}
                        name="region"
                        type="text"
                        placeholder=""
                        success={undefined}
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn-main w-100"
                    disabled={loading}
                  >
                    {loading ? <span className="loader"></span> : t("Edit")}
                  </button>
                </FormField>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ModalEditInfoAccount;
