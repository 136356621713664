import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import InputFiled from "../InputForm/InputField";
import FormField from "../FormComponent/FormFiled";
import LogoImageForm from "../LogoForm/LogoImageForm";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ImageComponent from "../ImageLogin/ImageComponent";
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../../axios";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import TermsConditionsModal from "Pages/TermsConditions/TermsConditionsModal";
import LanguageSwitcher from "Components/Languages/LanguageSwitcher";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  password: "",
  region: "",
};

const RegisterForm = () => {
  const [cites, setCites] = useState([]);
  const [Type, setType] = useState("customer");
  const [countryError, setCountryError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [showTerms, setShowTerms] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsCheckedError, setTermsCheckedError] = useState(false);
  const isEmiratiPhoneNumber = (val) => {
    // Regular expression to match Emirati phone numbers without +971
    const uaeRegex = /^(0?5\d{8})$/;

    return uaeRegex.test(val);
  };

  const validationSchemaforCustomer = Yup.object().shape({
    name: Yup.string().required(t("EnterName")),
    email: Yup.string().email(t("EnterEmail")).required(t("EnterEmail")),
    phone: Yup.string()
      .matches(/^(0?5\d{8})$/, "Invalid UAE phone number")

      .test(
        "is-emirati-number",
        "Phone number must be Emirati",
        isEmiratiPhoneNumber
      )
      .required(t("Phone is required")),

    password: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
  });
  const validationSchemaforNotCustomer = Yup.object().shape({
    name: Yup.string().required(t("EnterName")),
    company_name_ar: Yup.string().required(t("EnterCompanyName")),
    company_name_en: Yup.string().required(t("EnterCompanyName")),
    email: Yup.string().email(t("EnterEmail")).required(t("EnterEmail")),
    phone: Yup.number()
      .typeError(t("PhoneTypeError"))
      .positive(t("PhonePositiveError"))
      .integer(t("PhoneIntegerError"))
      .min(1000000000, t("PhoneMinError"))
      .max(9999999999, t("PhoneMaxError"))
      .required(t("PhoneRequiredError")),
    password: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
  });
  const customStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      maxHeight: "50px",
      minHeight: "50px !important",
    }),

    input: (styles) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#565656",
      fontWeight: "600",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
      display: "flex",
      alignItems: "center",
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
    }),
  };
  const Router = useNavigate();
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    if (!selected) return setCountryError(true);
    if (!termsChecked) return setTermsCheckedError(true);
    setLoading(true);
    let phoneNumber;

    if (values.phone.startsWith("0")) {
      phoneNumber = values.phone.slice(1);
    } else {
      phoneNumber = values.phone;
    }
    axiosInstance
      .post("/register", {
        ...values,
        city_id: selected.value,
        type: Type,
        phone: phoneNumber,
      })
      .then((res) => {
        setLoading(false);

        toast.success(t("RegisterSuccess"));
        console.log(res);
        setLoading(false);
        Router("/loginForm");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.errors.email) {
          toast.error(t("EmailExist"));
        } else if (err.response.data.errors.phone) {
          toast.error(t("PhoneExist"));
        } else {
          toast.error(t("UnknownError"));
        }
      });
  };
  useEffect(() => {
    axiosInstance
      .get("/city", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const data = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });

        setCites(data);
      });
  }, []);
  // SELECTED
  const [selected, setSelected] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <HelmetInfo titlePage={t("RegisterTitle")} />
      <div className="login-form padding-xy">
        {/* ========= START LOGO LOGIN ======== */}
        <div className="logo-top">
          <LogoImageForm />
        </div>
        {/* ========= END LOGO LOGIN ======== */}
        {/* ========= START CONTAINER =========== */}
        {/* ========= change to Vercel ;) =========== */}

        <TermsConditionsModal show={showTerms} setShow={setShowTerms} />
        <div className="container-fluid">
          {/* ======== START ALL LOGIN FORM =========== */}
          <div className="all-Login-form">
            {/* ========== START ROW =========== */}
            <div className="row g-3 align-items-center">
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO FORM LOGIN =========== */}
                <div className="info-form-login position-relative">
                  {/* ========= START ICON CLOSE ========= */}

                  <div className="formheadernewx01">
                    <IconHeaderForm
                      routerPage={"/LoginForm"}
                      iconHeader={faArrowRight}
                    />
                    <LanguageSwitcher />
                  </div>
                  {/* ========= END ICON CLOSE ========= */}
                  {/* ========= START ALL FORM LOG ======== */}
                  <div className="all-form-log">
                    {/* ========== START HEADER FORM LOG ========= */}
                    <HeaderForm
                      titleHeaderForm={t("AccountInfoTitle")}
                      textHeaderForm={t("AccountInfoText")}
                    />
                    {/* ========== END HEADER FORM LOG ========= */}

                    {/* ========== START FORM FIELD ========= */}
                    <FormField
                      initialValues={initialValues}
                      validationSchema={validationSchemaforCustomer}
                      onSubmit={handleSubmit}
                    >
                      <div className="row g-3">
                        <div className="col-12 col-md-12">
                          <InputFiled
                            label={t("NameLabel")}
                            name="name"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("EmailLabel")}
                            name="email"
                            type="email"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("PhoneLabel")}
                            name="phone"
                            type="number"
                            success
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <ReactSelect
                            placeholder={t("SelectCityPlaceholder")}
                            options={cites}
                            onChange={(e) => {
                              setSelected(e);
                              console.log(e);
                            }}
                            styles={customStyle}
                            className=" mt-3"
                          />
                          {countryError && (
                            <div className="error">{t("CountryError")}</div>
                          )}
                        </div>
                        <div className="col-12 col-md-6">
                          <InputFiled
                            label={t("RegionLabel")}
                            name="region"
                            type="text"
                            placeholder=""
                            success
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="input-pass position-relative">
                            <InputFiled
                              label={t("PasswordLabel")}
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder=""
                              success
                              value={password}
                              onChange={handlePasswordChange}
                            />

                            <span
                              className="icon-eye-button"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-check check-input-field d-flex align-items-center gap-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={(e) => setTermsChecked(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {t("TermsAgreement")}{" "}
                          <span
                            onClick={() => setShowTerms(true)}
                            className="link-check"
                          >
                            {t("TermsLink")}
                          </span>
                        </label>
                      </div>
                      {termsCheckedError && (
                        <div className="error">{t("TermsError")}</div>
                      )}

                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-main btn-submit w-100 mt-5"
                      >
                        {loading ? (
                          <span className="loader"></span>
                        ) : (
                          t("ContinueButton")
                        )}
                      </button>
                    </FormField>
                    {/* ========== END FORM FIELD ========= */}
                  </div>
                  {/* ========= END ALL FORM LOG ======== */}
                </div>
                {/* ========== END INFO FORM LOGIN =========== */}
              </div>
              {/* =========== END COL ======== */}
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO LOGIN ========== */}
                <div className="info-login position-relative">
                  {/* ========= START IMAGE LOGIN ======= */}
                  <ImageComponent />
                  {/* ========= END IMAGE LOGIN ======= */}
                </div>
                {/* ========== END INFO LOGIN ========== */}
              </div>
              {/* =========== END COL ======== */}
            </div>
            {/* ========== END ROW =========== */}
          </div>
          {/* ======== END ALL LOGIN FORM =========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </div>
    </>
  );
};

export default RegisterForm;
