// import { useTranslation } from "react-i18next";
// import uploadImage from "../../assets/images/icons/upload.svg";

// const DownloadButton = ({ fileUrl, fileName }) => {
//   const handleDownload = () => {
//     // Create a temporary link element
//     const link = document.createElement("a");
//     link.href = fileUrl;
//     link.download = "ملف المشروع";
//     link.target = "_blank";

//     // Append the link to the body
//     document.body.appendChild(link);

//     // Trigger the click event on the link
//     link.click();

//     // Clean up by removing the link
//     document.body.removeChild(link);
//   };
//   const { t } = useTranslation();

//   return (
//     <button className="file-upload-info file-up" onClick={handleDownload}>
//       <div className="image-upload">
//         <img src={uploadImage} alt="upload icon" loading="lazy" />
//       </div>
//       <div className="info-content">
//         <h2 className="title">{t("fileUpload")}</h2>
//       </div>
//     </button>
//   );
// };
// export default DownloadButton;

// import { useTranslation } from "react-i18next";
// import uploadImage from "../../assets/images/icons/upload.svg";

// const DownloadButton = ({ fileUrl, fileName = "ملف المشروع" }) => {
//   const handleDownload = () => {
//     if (!fileUrl) {
//       console.error("File URL is not provided!");
//       return;
//     }

//     // Create a temporary link element
//     const link = document.createElement("a");
//     link.href = fileUrl;
//     link.download = fileName; // Use the provided file name or a default name
//     link.target = "_blank";

//     // Trigger the click event directly without appending/removing from DOM
//     link.click();
//   };

//   const { t } = useTranslation();

//   return (
//     <button className="file-upload-info file-up" onClick={handleDownload}>
//       <div className="image-upload">
//         {/* Use fallback in case the image is not found */}
//         <img
//           src={uploadImage}
//           alt={t("uploadIcon", "Upload Icon")}
//           loading="lazy"
//         />
//       </div>
//       <div className="info-content">
//         {/* Provide a fallback translation */}
//         <h2 className="title">{t("fileUpload", "Upload File")}</h2>
//       </div>
//     </button>
//   );
// };

// export default DownloadButton;

import { useTranslation } from "react-i18next";
import uploadImage from "../../assets/images/icons/upload.svg";

const DownloadButton = ({ fileUrl, fileName = "ملف المشروع" }) => {
  const { t } = useTranslation();

  return (
    <a
      href={fileUrl}
      download={fileName} // Automatically suggests downloading the file with this name
      target="_blank"
      rel="noopener noreferrer"
      className="file-upload-info file-up"
    >
      <div className="image-upload">
        <img
          src={uploadImage}
          alt={t("uploadIcon", "Upload Icon")}
          loading="lazy"
        />
      </div>
      <div className="info-content">
        <h2 className="title">{t("fileUpload", "تحميل الملف")}</h2>
      </div>
    </a>
  );
};

export default DownloadButton;
