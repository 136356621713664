// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./Home.css";
import HeaderTopHome from "./HeaderTop/HeaderTopHome";
import CardsInfo from "./CardsInfo/CardsInfo";
import BannerSlide from "./BannerSlide/BannerSlide";
import OffersPackages from "./OffersPackages/OffersPackages";
import Experts from "./Experts/Experts";
import RecentProjects from "./RecentProjects/RecentProjects";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { setuseId } from "../../Redux/auth";
import { useTranslation } from "react-i18next";
import CustomModal from "Components/CustomModal/CustomModal";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import ExtaPackagePopUp from "Pages/AllProjects/ExtaPackagePopUp";
import imgIcon from "../../assets/images/icons/diamond.svg";
import i18next from "i18next";
import ExtraSubscriptionModal from "Pages/MyAccount/ModalAccount/ExtraSubscribeModal";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "Pages/Wallet/Stripe";
import PaymentSuccessLottie from "Components/LandingPageComponent/PaymentSuccessLotties/PaymentSuccessLottie";
import styles from "../AllProjects/extrapackagepopup.module.css";
import stylesx from "../../Pages/MyAccount/ModalAccount/extrasubscrive.module.css";
import { loadStripe } from "@stripe/stripe-js";

const Home = () => {
  const stripePromise = loadStripe(
    "pk_live_51P5XBHRozbqwhoPVH68Crl9g2kO2lLn8dWnD7zAtlDt05lqA2Why33vUdZdG85z4C6mz4rWz02Puo3vQZ1R4m2Xm00JT6fxYqC"
  );
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [projects, setProjects] = useState([]);
  const [Offers, setOffers] = useState([]);
  const [views, setView] = useState([]);
  const [Slider, setSlider] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [price, setPrice] = useState(0);
  const [loading2, setLoading2] = useState(false);
  const [amount, setAmount] = useState(null); // Store amount to be passed to HandleAmount
  const [clientSecret, setclientSecret] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [ActiveId, setActiveId] = useState(0);
  const [isYearly, setIsYearly] = useState(false);
  const [active, setActive] = useState();
  const [ClientLoadingg, setClientLoading] = useState(false);
  const [paymentsuccessLottiex, setpaymentsuccessLottie] = useState(false);
  const [percent, setPercent] = useState(0);
  const type = useSelector((state) => state.auth.type);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [checkOffersNumber, setcheckOffersNumber] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance
      .get("/web/home", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.projects.length);
        // setLoading(false);
        setProviders(res.data.providers);
        setAdvisor(res.data.advisors);
        setContractor(res.data.contractors);
        setProjects(res.data.projects);
        setOffers(res.data.latest_offers);
        setView(res.data.mytotalviews);
        setSlider(res.data.slider);
        setData(res.data);
        console.log(res.data);
      });
  }, [i18n.language]);
  useEffect(() => {
    axiosInstance
      .get("/profile", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setProfileData(res.data.data);
        setcheckOffersNumber(
          res.data.data.type === "customer"
            ? false
            : res.data.data.projects_apply > 1
            ? false
            : true
        );
        console.log(res.data.data);
        setPercent(res.data.data.percent);
        dispatch(setuseId(res.data.data.id));
      });
  }, []);

  //
  const [showExtraPackagePopUp, setshowExtraPackagePopUp] = useState(false);
  const [ExtraPackages, setExtraPackages] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/extrapackages", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setExtraPackages(res.data.data);
        console.log(res.data.data);
      });
  }, []);

  const openModal = (packageData, price) => {
    setSelectedPackage(packageData);
    setPrice(price);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);
  const [stripe_ID, setstripe_ID] = useState();
  const [UserData, setUserData] = useState();
  useEffect(() => {
    axiosInstance
      .get("/profile")
      .then((res) => res.data)
      .then((data) => {
        setstripe_ID(data.data.stripe_id);
        setUserData(data.data);
      });
  }, []);
  const HandleAmount = (num, setLoaidng) => {
    setAmount(num);

    if (num != null) {
      console.log("hello");

      setClientLoading(true);
      axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          {
            amount: num * 100,
            currency: "aed",
            "payment_method_types[]": "card",
            ...(stripe_ID ? { customer: stripe_ID } : {}),
            description: `ID: ${UserData?.id}, Email: ${
              UserData?.email
            }, Name: ${
              UserData?.name
            }, Date: ${new Date().toLocaleDateString()}, Time: ${new Date().toLocaleTimeString()}, Duration: "Extra Package", Price : ${price}`,
          },
          {
            headers: {
              Authorization:
                "Bearer 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",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setclientSecret(res.data.client_secret);
          console.log(res.data.client_secret);
          setTimeout(() => {
            setClientLoading(false);
            setPaymentModal(true);
            setShowModal(false);
            setLoaidng(false);
          }, 500);
        })
        .catch((err) => {
          toast.error(t("Failed to subscribe"));
          setLoaidng(false);
        });
    }
  };

  const handleAfterPay = (id, setIsProcessing) => {
    setIsProcessing(true);
    axiosInstance
      .post("/extrasubscription", {
        package_id: ActiveId,
        payment_method: "stripe",
        transaction_id: id,
      })
      .then((res) => {
        toast.success(t("Subscribed successfully"));
        setpaymentsuccessLottie(true);
        setIsProcessing(false);

        setPaymentModal(false);
      })
      .catch((error) => {
        toast.error(t("Failed to subscribe"));
        setIsProcessing(false);
        setPaymentModal(false);
      });
  };

  return (
    <div>
      <HelmetInfo titlePage={"الصفحة الرئيسية"} />
      <main className="position-relative z-0 pt-3">
        {/* <HeaderTopHome percent={percent} /> */}
        <CardsInfo
          projectNumb={data?.mytotalProjects}
          offersnum={data?.mytotalOffers}
          views={profileData?.projects_apply}
          views2={data?.mytotalviews}
        />
        <BannerSlide data={Slider} />
        {type !== "customer" && (
          <div className="mb-3">
            {profileData?.percent < 100 && (
              <HeaderTopHome
                editPassword={false}
                data={profileData}
                editProfileButton={false}
                goToProfile={true}
              />
            )}
          </div>
        )}
        <OffersPackages data={Offers} subscription={data?.subscription} />
        <Experts
          advisor={advisor}
          providers={providers}
          contractor={contractor}
        />
        {type != "customer" && <RecentProjects projects={projects} />}
        {checkOffersNumber ? (
          <CustomModal
            show={true}
            onHide={() => {
              setcheckOffersNumber(false);
            }}
          >
            <div className="x035parent">
              <h1>{t("titlexr35")}</h1>
              <div className="x035child">
                <p className="x035childp">{t("descriptionxr35")}</p>
                <Link
                  to={"/packagesCards"}
                  className="x035childbutton btn-main"
                >
                  {t("basicPackagesButtonxr35")}
                </Link>
              </div>
              <div className="x035child">
                <p className="x035childp">{t("extraOptionxr35")}</p>
                <button
                  className="x035childbutton btn-main"
                  onClick={() => {
                    setshowExtraPackagePopUp(true);
                    setcheckOffersNumber(false);
                    console.log(showExtraPackagePopUp);
                  }}
                >
                  {t("extraPackagesButtonxr35")}
                </button>
              </div>
            </div>
          </CustomModal>
        ) : null}
        {showExtraPackagePopUp ? (
          <ExtaPackagePopUp
            show={showExtraPackagePopUp}
            onHide={() => setshowExtraPackagePopUp(false)}
            title={t("totleoffersbutton")}
            size={
              ExtraPackages?.length >= 3
                ? "lg"
                : ExtraPackages?.length === 2
                ? "m"
                : "m"
            }
          >
            <div className={styles.extrapageParent}>
              {ExtraPackages?.map((e, i) => {
                return (
                  <div key={i} className={styles.extrapage}>
                    <div className={styles.extrapagetitle}>
                      <img src={imgIcon} alt="" className="" />
                      <h1>
                        {i18next.language === "en"
                          ? e.title_tr.en
                          : e.title_tr.ar}
                      </h1>
                    </div>
                    <span className={styles.linnnnnnne}></span>
                    <div className={styles.extrapagePrice}>
                      <h1>{e.price}</h1>
                      {t("AED")}
                    </div>
                    <span className={styles.linnnnnnne}></span>
                    <div className={styles.extrapageOffers}>
                      <h4>{t("totleoffersNewAdditional")}</h4>
                      <div className={styles.extrapageOfferCount}>
                        <h1>{e.offers_count}</h1>
                        {t("offerText")}
                      </div>
                    </div>
                    <span className={styles.linnnnnnne}></span>
                    <button
                      className="btn-main"
                      onClick={() => {
                        openModal({ id: e.id, title: e.title }, e.price);
                        setshowExtraPackagePopUp(false);
                      }}
                    >
                      {t("subscribe now")}
                    </button>
                  </div>
                );
              })}
            </div>
          </ExtaPackagePopUp>
        ) : null}
        {showModal && (
          <ExtraSubscriptionModal
            ShowModal={selectedPackage}
            handleCloseModalEditWork={closeModal}
            price={price}
            HandleAmount={HandleAmount}
          />
        )}
        <CustomModal show={paymentModal} onHide={() => setPaymentModal(false)}>
          {ClientLoadingg ? (
            <div>Loading</div>
          ) : (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
                appearance: { theme: "flat" },
              }}
            >
              <CheckoutForm amount={amount} handleAfterPay={handleAfterPay} />
            </Elements>
          )}
        </CustomModal>
      </main>
      {paymentsuccessLottiex ? (
        <div
          className={styles.paymentSuccessPopUp}
          style={{ zIndex: 10000000000000000 }}
        >
          <PaymentSuccessLottie />
        </div>
      ) : null}
    </div>
  );
};

export default Home;
