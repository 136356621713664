import { useEffect, useState } from "react";
import imageOwner from "../../assets/images/user/userImage.png";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import DownloadButton from "Components/Button/DownloadBtn";
import { Link } from "react-router-dom";
import {
  faCommentDots,
  faStar,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./popupparent.css";

const InfoOwnerDetails = ({ data, showChatBtn, setchatData, showProvider }) => {
  useEffect(() => {
    console.log(data);
  }, []);
  const notify = () => toast("Wow so easy!");
  const [popimage, setPopimage] = useState(false);
  const [popimagesrc, setpopimagesrc] = useState("");

  const { t } = useTranslation();

  const popUpImage = (src) => {
    setpopimagesrc(src);
    console.log(src);
    console.log(popimage);
    setPopimage(true);
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setPopimage(false);
    }
  });
  return (
    <>
      {/* ======== START OWNER PROJECT DETAILS ========= */}
      <div className="owner-project-details">
        {showProvider ? (
          <div className="d-flex justify-content-between">
            <div className="user-owner-det d-flex align-items-center gap-3">
              {/* ========= START IMAGE OWNER ======= */}
              <div className="image-owner">
                <img
                  src={showProvider.image}
                  alt="imageOwner"
                  width={"75px"}
                  height={"75px"}
                  className="object-fit-cover rounded-circle "
                />
              </div>
              <div className="info-owner">
                <h2 className="name">{data?.provider?.name} </h2>
                <p className="text-owner">{data?.provider?.type} </p>
              </div>
              {/* ========= END IMAGE OWNER ======= */}
            </div>
            <div className="button-conversation">
              <Link
                to={{
                  pathname: "/chat",
                }}
                state={{ ...showProvider, newChat: true }}
                className="btn-main mt-0"
              >
                {t("buttonConversation")}{" "}
                <FontAwesomeIcon icon={faCommentDots} />
              </Link>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <div className="user-owner-det d-flex align-items-center gap-3">
              {/* ========= START IMAGE OWNER ======= */}
              <div className="image-owner">
                <img
                  src={data?.customer?.image}
                  alt="imageOwner"
                  width={"75px"}
                  height={"75px"}
                  className="object-fit-cover rounded-circle "
                />
              </div>
              <div className="info-owner">
                <h2 className="name">{data?.customer?.name} </h2>
                <p className="text-owner">{t("projectOwner")}</p>
              </div>
              {/* ========= END IMAGE OWNER ======= */}
            </div>
            {showChatBtn && (
              <div className="button-conversation">
                <Link
                  to={{
                    pathname: "/chat",
                  }}
                  state={setchatData}
                  className="btn-main mt-0"
                >
                  {t("buttonConversation")}{" "}
                  <FontAwesomeIcon icon={faCommentDots} />
                </Link>
              </div>
            )}
          </div>
        )}
        {/* ========= START OWNER DETAILS INFO ========= */}
        <div className="owner-details-info">
          <div className="details-one-project">
            <div className="info-details">
              <h2 className="title">{t("Therequiredjob")}</h2>
              <div className="d-flex align-items-center">
                <h5>{t("job")} : </h5>{" "}
                <h5
                  className="jobname"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  {t(`${data?.type}`)}
                </h5>
              </div>
              {(() => {
                if (data?.type === "provider") {
                  return (
                    <div className="d-flex align-items-center pt-3">
                      <p
                        className=""
                        style={{
                          fontWeight: "600",
                          color: "#002c63",
                          fontSize: "17px",
                        }}
                      >
                        {t("typeofsupply")} :
                      </p>
                      <span className="ps-2 pe-2" style={{ fontSize: "17px" }}>
                        {data?.category}
                      </span>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          <div className="details-one-project">
            <div className="info-details">
              <h2 className="title">{t("aboutProject")}</h2>
              <p
                className="text"
                style={{ fontSize: "18px", fontWeight: "500" }}
              >
                {data?.content}
              </p>
            </div>
            {data?.file && (
              <div className="file-upload-project">
                <DownloadButton fileUrl={data?.file} />
              </div>
            )}
          </div>
          <div className="details-one-project">
            <div className="info-details">
              <h2 className="title" style={{ fontWeight: "blod" }}>
                {t("projectRequirements")}
              </h2>
              <p
                className="text"
                style={{ fontSize: "18px", fontWeight: "500" }}
              >
                {data?.requirments}
              </p>
            </div>
          </div>
          <div className="details-one-project">
            <div className="info-details">
              <h2 className="title pb-2">{t("projectimages")}</h2>
              {/*<div className="d-flex justify-content-around align-items-center flex-wrap">
                {data?.images?.length !== 0
                  ? data?.images?.map((e, i) => {
                      return (
                        <img
                          src={e}
                          alt="image"
                          className={`$`}
                          key={i}
                          style={{
                            width: "130px",
                            cursor: "pointer",
                            margin: "10px",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            popUpImage(e);
                            setPopimage(true);
                            console.log("test");
                          }}
                        />
                      );
                    })
                  : null}
              </div>*/}
              <div className="d-flex justify-content-around align-items-center flex-wrap">
                {data?.images?.length > 0 &&
                !data.images.some((img) => !/default\.png$/.test(img)) ? (
                  <p className="btn-main">{t("thereisnoimageinthisproject")}</p>
                ) : (
                  data?.images?.map((e, i) => (
                    <img
                      src={e}
                      alt="image"
                      className=""
                      key={i}
                      style={{
                        width: "130px",
                        cursor: "pointer",
                        margin: "10px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        popUpImage(e);
                        setPopimage(true);
                        console.log("test");
                      }}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        {/* ========= END OWNER DETAILS INFO ========= */}
        {(() => {
          if (popimage) {
            return (
              <div className="popupparent">
                <button className="button" onClick={() => setPopimage(false)}>
                  x
                </button>
                <img src={popimagesrc} alt="" />
              </div>
            );
          }
        })()}
        {/* ========= START SHARING DETAILS INFO ========= */}
        {/* ========= END SHARING DETAILS INFO ========= */}
      </div>
      {/* ======== END OWNER PROJECT DETAILS ========= */}
    </>
  );
};

export default InfoOwnerDetails;
