import style from "./offersShowCard.module.css";
import avatar from "../../../src/assets/images/user/userImage.png";
import crown from "../../assets/images/icons/crown (1).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import verified from "../../assets/images/icons/verified.png";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
function OffersShowCard({ offerData }) {
  const { t } = useTranslation();
  console.log(offerData);
  return (
    <div className={style.parentDiv}>
      <div className={style.header}>
        <div className={style.nameData}>
          <div className={style.image}>
            <img src={offerData.provider.company_logo} alt="alt" />
          </div>
          <div className={style.name}>
            <p>
              {(i18n.language == "en"
                ? offerData.provider?.company_name_en
                : offerData.provider?.company_name_ar) ||
                offerData.provider?.name}
              {offerData.provider?.is_verified ? (
                <img src={verified} alt="" className="p-1" />
              ) : null}
            </p>
            <div className={style.reate}>
              <FontAwesomeIcon icon={faStar} style={{ color: "#ffb300" }} />
              <span>4.0</span>
            </div>
          </div>
        </div>
        {offerData.provider.show_in_home === 1 ? (
          <div className={style.permumtype}>
            <div className="premiemAccount">
              {t(`featured_${offerData.provider.type}`)}
              <div className="p-1">
                <img src={crown} alt="" />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={style.contenttitle}>{t("offer_content")}</div>
      <div className={style.content}>{offerData.content.slice(0, 50)}...</div>
    </div>
  );
}

export default OffersShowCard;
