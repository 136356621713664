import { Tab, Tabs } from "react-bootstrap";
import img1 from "../../../assets/LandingPageImages/main/01.png";
import img2 from "../../../assets/LandingPageImages/main/02.png";
import img3 from "../../../assets/LandingPageImages/main/03.png";
import iconLocation from "../../../assets/LandingPageImages/icons/location.svg";
import iconcalendar from "../../../assets/LandingPageImages/icons/calendar.svg";
import iconDollar from "../../../assets/LandingPageImages/icons/dollar-circle.svg";
import iconEye from "../../../assets/LandingPageImages/icons/eye.svg";
import iconDocument from "../../../assets/LandingPageImages/icons/document.svg";
import "./LatestAdditions.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
const LatestAdditions = () => {
  const { t, i18n } = useTranslation();
  const cards = [
    { id: 0, img: img1 },
    { id: 1, img: img2 },
    { id: 2, img: img3 },
  ];

  const [projects, setProjects] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [advisors, setAdvisors] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/webprojects", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res?.data.allprojects);
        setProjects(res?.data?.allprojects);
        setContractors(res?.data?.contractors);
        setSuppliers(res?.data?.providers);
        setAdvisors(res?.data?.advisors);
      });
  }, [i18n.language]);
  return (
    <>
      <section
        className="latest-additions padding bg-section"
        id="latest-additions"
      >
        <div className="container">
          <SectionTitle
            titleSection={t("Latest Additions")}
            text={t(
              "A group of contractors, consultants, suppliers, and recent projects in the application"
            )}
            dataAnimationType={"fade-left"}
          />

          <div className="all-latest-additions">
            <Tabs
              defaultActiveKey="projects"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="projects" title={t("Projects")}>
                <div className="latest-addition-one">
                  <div className="row g-3">
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      dir="rtl"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 2,
                        },
                      }}
                      className="mySwiper"
                    >
                      {projects?.map((item, index) => {
                        return (
                          <SwiperSlide key={item.id}>
                            {" "}
                            <div className="pb-5" key={index}>
                              <div className="card-addition-one h-100">
                                <div className="main-card-info">
                                  <div className="image-card-addition">
                                    <img
                                      src={item.images}
                                      alt="img"
                                      className="w-100 h-100 object-fit-cover"
                                      loading="lazy"
                                    />
                                    <div className="ribbon-card">
                                      {t("Creation")}
                                    </div>
                                  </div>
                                  <div className="info-content">
                                    <h2 className="title">{item?.title}</h2>
                                    <div className="location-info d-flex align-items-center gap-2">
                                      <div className="icon">
                                        <img
                                          src={iconLocation}
                                          alt="icon location"
                                          loading="lazy"
                                        />
                                      </div>
                                      <p className="text">{item?.region}</p>
                                    </div>
                                    <div className="date-price-info d-flex justify-content-between align-items-center gap-3 flex-wrap py-3">
                                      <div className="date-info d-flex align-items-center gap-2">
                                        <div className="icon">
                                          <img
                                            src={iconcalendar}
                                            alt="icon calendar"
                                            loading="lazy"
                                          />
                                        </div>
                                        <div className="info-content-date">
                                          <h2 className="date-text text-top">
                                            {item?.end_date}
                                          </h2>
                                          <p className="text">
                                            {t("Expected delivery date")}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="price-info d-flex align-items-center gap-2">
                                        <div className="icon">
                                          <img
                                            src={iconDollar}
                                            alt="icon Dollar"
                                            loading="lazy"
                                          />
                                        </div>
                                        <div className="info-content-price">
                                          <h2 className="price-text text-top">
                                            {item?.price} AED
                                          </h2>
                                          <p className="text">
                                            {t("Expected value")}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="offer-watch d-flex align-items-center gap-3">
                                      <div className="watch-info d-flex align-items-center gap-2">
                                        <div className="icon">
                                          <img
                                            src={iconEye}
                                            alt="icon Eye"
                                            loading="lazy"
                                          />
                                        </div>
                                        <p className="text">
                                          <span className="num-watch">
                                            {item?.views}
                                          </span>{" "}
                                          {t("Views")}
                                        </p>
                                      </div>
                                      {/* <div className="offer-info d-flex align-items-center gap-2">
                                  <div className="icon">
                                    <img
                                      src={iconDocument}
                                      alt="icon Document"
                                      loading="lazy"
                                    />
                                  </div>
                                  <p className="text">
                                    <span className="num-watch">32</span> {t("Offers")}
                                  </p>
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="contractors" title={t("Contractors")}>
                <div className="latest-addition-one">
                  <div className="row g-3">
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      dir="rtl"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 2,
                        },
                      }}
                      className="mySwiper"
                    >
                      {contractors?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="" key={item.id}>
                              <div className="card-addition-one h-100">
                                <div className="main-card-info">
                                  <div className="header-card d-flex align-items-center gap-3">
                                    <div className="image-card-addition">
                                      <img
                                        src={item.image}
                                        alt="img"
                                        className="w-100 h-100 object-fit-cover"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="header-info-card d-flex flex-column gap-2">
                                      <h2 className="title">{item.name}</h2>
                                      <div className="rate-star d-flex align-items-center gap-1">
                                        <i className="fa-solid icon-star fa-star" />
                                        <span className="num-rate"> 4.8</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="info-content">
                                    <div className="info-content-card d-flex justify-content-between align-items-center gap-2">
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">
                                          {t("Activity")}
                                        </h2>
                                        <p className="title">{t("Building")}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Emirate")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Area")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                    </div>
                                    <p className="text py-3">
                                      {item.about?.slice(0, 50)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="suppliers" title={t("Suppliers")}>
                <div className="latest-addition-one">
                  <div className="row g-3">
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      dir="rtl"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 2,
                        },
                      }}
                      className="mySwiper"
                    >
                      {suppliers?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="" key={item.id}>
                              <div className="card-addition-one h-100">
                                <div className="main-card-info">
                                  <div className="header-card d-flex align-items-center gap-3">
                                    <div className="image-card-addition">
                                      <img
                                        src={item.image}
                                        alt="img"
                                        className="w-100 h-100 object-fit-cover"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="header-info-card d-flex flex-column gap-2">
                                      <h2 className="title">{item.name}</h2>
                                      <div className="rate-star d-flex align-items-center gap-1">
                                        <i className="fa-solid icon-star fa-star" />
                                        <span className="num-rate"> 4.8</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="info-content">
                                    <div className="info-content-card d-flex justify-content-between align-items-center gap-2">
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">
                                          {t("Activity")}
                                        </h2>
                                        <p className="title">{t("Building")}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Emirate")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Area")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                    </div>
                                    <p className="text py-3">
                                      {item.about?.slice(0, 50)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="advisors" title={t("Advisors")}>
                <div className="latest-addition-one">
                  <div className="row g-3">
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      dir="rtl"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 2,
                        },
                      }}
                      className="mySwiper"
                    >
                      {advisors?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="" key={item.id}>
                              <div className="card-addition-one h-100">
                                <div className="main-card-info">
                                  <div className="header-card d-flex align-items-center gap-3">
                                    <div className="image-card-addition">
                                      <img
                                        src={item.image}
                                        alt="img"
                                        className="w-100 h-100 object-fit-cover"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="header-info-card d-flex flex-column gap-2">
                                      <h2 className="title">{item.name}</h2>
                                      <div className="rate-star d-flex align-items-center gap-1">
                                        <i className="fa-solid icon-star fa-star" />
                                        <span className="num-rate"> 4.8</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="info-content">
                                    <div className="info-content-card d-flex justify-content-between align-items-center gap-2">
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">
                                          {t("Activity")}
                                        </h2>
                                        <p className="title">{t("Building")}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Emirate")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                      <div className="one-info d-flex flex-column gap-1">
                                        <h2 className="text">{t("Area")}</h2>
                                        <p className="title">{item.region}</p>
                                      </div>
                                    </div>
                                    <p className="text py-3">
                                      {item.about?.slice(0, 50)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestAdditions;
